import { ContentfulNextJsImage } from '@components/common/ContentfulImage'
import { COMING_SOON_TAG } from '@components/product/product.constants'
import { formatCurrency } from '@commerce/product/use-price'
import Link from 'next/link'
import React from 'react'
import { TProductType } from 'types/contentful/productCategory'
import { TProductTile } from './ProductTile.types'
import { useCommerce } from '@commerce'
import { NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY } from '@utils/client-side-config'
import { ProductTag } from '../ProductTag'

function PriceArea({
  pricePerUnit,
  salePricePerUnit,
  productType,
}: {
  pricePerUnit: number
  salePricePerUnit: number
  productType: TProductType
}) {
  const { locale } = useCommerce()

  if (productType === 'Plus') {
    return (
      <span className="text-acai text-[14px] lg:text-[16px]">
        Request a Quote
      </span>
    )
  }

  if (!Number.isFinite(pricePerUnit)) {
    return (
      <span className="text-acai text-[14px] lg:text-[16px]">
        {COMING_SOON_TAG}
      </span>
    )
  } else {
    const originalPrice = formatCurrency({
      amount: pricePerUnit,
      currencyCode: NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY,
      locale,
      wideFormatForFractions: false,
    })

    const salesPrice = formatCurrency({
      amount: salePricePerUnit,
      currencyCode: NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY,
      locale,
      wideFormatForFractions: false,
    })

    return (
      <div className="leading-8">
        <p className="text-boulder text-[14px] lg:text-[16px]">
          from&nbsp;
          <span className="text-acai lg:group-hover:text-pitaya">
            <span className="font-semibold">
              {salePricePerUnit ? salesPrice : originalPrice}
            </span>
            &nbsp;per unit
          </span>
        </p>

        {salePricePerUnit ? (
          <p className="text-[12px] lg:text-xl text-black-80">
            <span className="line-through font-semibold">{originalPrice}</span>{' '}
            per unit
          </p>
        ) : null}
      </div>
    )
  }
}

export function ProductTile({
  primaryImage,
  imageBackgroundColor,
  imageTags,
  productTags,
  productName,
  pricePerUnit,
  salePricePerUnit,
  productDetails,
  linkUrl,
  productType,
  onClick,
  priorityLoad = false,
  compactLayout = false,
}: TProductTile) {
  return (
    <Link
      href={linkUrl}
      passHref
      className={`
      ${compactLayout ? 'flex-col w-1/2 sm:w-auto mb-8' : ''}
      relative flex items-center sm:block bg-white px-4 sm:p-4 cursor-pointer
      font-mori group tracking-mori rounded-[20px] sm:rounded-b-[12px]
      sm:rounded-t-[36px] sm:hover:shadow-xl sm:border-[2px]
      sm:border-white sm:hover:border-black-5
      transition-all duration-300
    `}
      onClick={onClick}
    >
      <div
        className={`
        ${
          compactLayout
            ? 'w-full m-0 mb-6'
            : 'w-[45%] w-min-[45%] w-max-[45%] sm:w-full mb-6 mr-6 sm:mr-0'
        }
        relative rounded-[15px] sm:rounded-[32px]
        overflow-hidden aspect-square
        shrink-0
      `}
      >
        {imageTags?.length ? (
          <div className="absolute top-4 right-4 sm:top-7 sm:right-7 z-1 flex">
            {imageTags?.map((tag) => (
              <ProductTag
                key={`${tag.label}_${productName}`}
                type={tag.type}
                label={tag.label}
                labelImage={tag.labelImage}
                className={`ml-4 ${
                  imageTags?.length > 1 ? 'first:hidden md:first:flex' : ''
                }`}
              />
            ))}
          </div>
        ) : null}

        <ContentfulNextJsImage
          src={primaryImage?.imageUrl}
          alt={primaryImage?.imageTitle}
          quality={70}
          format="jpg"
          layout="fill"
          sizes="(max-width: 768px) 100vw, 33vw"
          backgroundColour={imageBackgroundColor}
          className="transition-all h-full duration-300 sm:group-hover:scale-105"
          priority={priorityLoad}
        />
      </div>

      {productDetails?.length !== 0 ? (
        <div className="hidden text-[14px] text-acai-120 sm:flex items-center flex-wrap mb-4 leading-[18px]">
          {productDetails?.map((detail, idx) => {
            return (
              <React.Fragment key={detail}>
                {idx !== 0 && (
                  /* Separating dot */
                  <span className="text-[18px] px-2">&#183;</span>
                )}
                <span>{detail}</span>
              </React.Fragment>
            )
          })}
        </div>
      ) : null}
      <div className="flex flex-col w-full">
        <h4 className="text-[18px] leading-9 text-acai font-semibold mb-2">
          {productName}
        </h4>

        <PriceArea
          pricePerUnit={pricePerUnit}
          salePricePerUnit={salePricePerUnit}
          productType={productType}
        />

        {productDetails?.length !== 0 ? (
          <div
            className={`
            ${compactLayout ? 'hidden' : 'flex'}
            text-[12px] text-acai-120 sm:hidden
            items-start flex-col mt-3 leading-[18px]
          `}
          >
            {productDetails?.map((detail, idx) => {
              return (
                <React.Fragment key={detail}>
                  <span>{detail}</span>
                </React.Fragment>
              )
            })}
          </div>
        ) : null}
      </div>
      <div className="hidden sm:flex absolute pb-0 px-4 w-[calc(100%+4px)] -translate-x-[12px] bg-white h-0 max-h-0 overflow-hidden text-2xl transition-all duration-300 rounded-lg sm:group-hover:h-auto sm:group-hover:max-h-[80px] flex-wrap items-start pt-5 rounded-b-[12px] border-[2px] border-black-10 sm:border-white sm:hover:border-black-5 border-t-0 group-hover:shadow-xl">
        {productTags?.map(
          (tag, index) =>
            index < 6 && (
              <ProductTag
                type={tag.type}
                label={tag.label}
                labelImage={tag.labelImage}
                className="mr-3 mb-3"
              />
            )
        )}
      </div>
    </Link>
  )
}
